import styled from "styled-components"
import device from "../shared/devices"

const Grid = styled.div`
    display: grid;
    margin: auto;
    padding: 0;
    width: 100%;

    @media ${device.mobile} {
      max-width: 290px;
      grid-template-columns: 130px 130px;
      grid-column-gap: 30px;
    }

    @media ${device.tablet} {
      max-width: 680px;
      grid-template-columns: 63px 63px 63px 63px 63px 63px;
      grid-column-gap: 50px;
    }

    @media ${device.desktop} {
      max-width: 960px;
      grid-template-columns: 100px 100px 100px 100px 100px 100px;
      grid-column-gap: 60px;
    }
`
export default Grid
