import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import device from "../shared/devices"
import Menu from "./menu"

const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #F8ECEC;
  z-index: 100;
`

const Container = styled.div`
  display: flex;
  margin: 40px;
  height: 20px;
  justify-content: space-between;
`

const Logo = styled.h1`
  text-transform: uppercase;
  font-family: AktivGrotesk;
  font-size: 9.6px;
  letter-spacing: 1.28px;
  order: 1;
  line-height: 7px;

  @media ${device.desktop} {
    font-size: 12px;
    letter-spacing: 1.6px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <Container>
      <Logo>
        <StyledLink to="/">
          Tech Team Berlin
        </StyledLink>
      </Logo>
      <Menu />
    </Container>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
