import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const MenuContainer = styled.div`
  order: 2;
  width: 26px;
  height: 20px;
`

const MenuButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`

const MenuBar = styled.div`
  width: 26px;
  height: 1px;
  background-color: #2700FF;
  margin: 4px 0;
`

const Overlay = styled.div`
  background-color: #3500FF;
  display: ${props => props.isOpen ? "block" : "none"};
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
`

const Close = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  position: absolute;
  top: 39px;
  right: 35px;
  height: 30px;
  width: 30px;
  &:before, &:after {
    position: absolute;
    top: -5px;
    left: 14px;
    content: ' ';
    height: 31px;
    width: 1px;
    background-color: #FFFFFF;
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const NavLinkContainer = styled.div`
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  a {
    align-self: center;
    display: block;
    font-family: AktivGrotesk;
    letter-spacing: 1.28px;
    font-size: 17.5px;
    color: #F6E5E0;
    font-weight: 100;
    text-decoration: none;
  }
`

const NavLink = (props) => (
  <NavLinkContainer><Link to={props.href} onClick={props.onClick}>{props.children}</Link></NavLinkContainer>
)

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: props.isOpen };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggleMenu() {
    this.setState(state => ({
      isOpen: !state.isOpen
    }))
  }

  goTo() {
    this.setState(state => ({
      isOpen: false
    }))
  }

  render() {
    return (
      <MenuContainer>
        <MenuButton onClick={this.toggleMenu} aria-label="menu" role="presentation">
          <MenuBar></MenuBar>
          <MenuBar></MenuBar>
          <MenuBar></MenuBar>
        </MenuButton>
        <Overlay isOpen={this.state.isOpen}>
          <Close onClick={this.toggleMenu} />

          <Navigation>
            <NavLink onClick={this.goTo} href="/#we-offer">We Offer</NavLink>
            <NavLink onClick={this.goTo} href="/#technologies">Technologies</NavLink>
            <NavLink onClick={this.goTo} href="/#testimonials">Testimonials</NavLink>
            <NavLink onClick={this.goTo} href="/#about-us">About Us</NavLink>
            <NavLink onClick={this.goTo} href="/#opensource">Open Source</NavLink>
            <NavLink onClick={this.goTo} href="/#initiatives">Initiatives</NavLink>
            <NavLink onClick={this.goTo} href="/#get-in-touch">Get in Touch</NavLink>
          </Navigation>
        </Overlay>
      </MenuContainer>
    )
  }
}

Menu.propTypes = {
  isOpen: PropTypes.bool
}

Menu.defaultProps = {
  isOpen: false
}
