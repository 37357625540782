import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import device from "../shared/devices"

const Container = styled.div`
  margin-top: 180px;
  background-color: #3500FF;
  padding: 40px;
  padding-top: 60px;
  padding-bottom: 100px;

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${device.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const LeftContainer = styled.div`
  display: flex;

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
  }

  @media ${device.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`


const FooterText = styled.p`
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #F8ECEC;
  font-family: AktivGrotesk;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-bottom: 20px;
  padding-right: 40px;
`

const FooterLink = styled(Link)`
  display: block;
  color: #F8ECEC;
  font-family: AktivGrotesk;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-bottom: 20px;
`

const MailLink = styled.a`
  display: block;
  color: #F8ECEC;
  font-family: AktivGrotesk;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-bottom: 20px;
`

const Logo = styled.div``
const Address = styled.div``
const Contact = styled.div``
const Imprint = styled.div`
  @media ${device.mobile} {
    padding-top: 80px;
  }

  @media ${device.tablet} {
    padding-top: 0px;
  }

  @media ${device.desktop} {
    padding-top: 0px;

  }
`

const Footer = () => (
  <Container>
    <LeftContainer>
      <Logo>
        <FooterText>
          Tech Team Berlin
        </FooterText>
      </Logo>
      <Address>
        <FooterText>
          Lausitzer Str. 10
          <br />Aufgang B
          <br />10969 Berlin
        </FooterText>
      </Address>
      <Contact>
        <MailLink href="mailto:contact@techteamberlin.com">
          contact@techteamberlin.com
        </MailLink>
      </Contact>
    </LeftContainer>
    <Imprint>
      <FooterLink to="/imprint">
        Imprint
      </FooterLink>
    </Imprint>
  </Container>
)

export default Footer
